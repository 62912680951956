import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
const TermsPage = ({data}) => (
    <Layout containerClass="terms">
        <SEO title="Allcor Fabrication - Terms" />
            <section className="section intro pad-100 downloads" id="main">
                <div className="uk-container uk-container-center uk-height-1-1">
                    <div className="uk-flex uk-flex-wrap uk-flex-middle uk-flex-center uk-height-1-1">
                        <div className="uk-width-1-1 uk-width-3-4@l">
                            <h2 className="h1" style={{marginTop: '50px'}}>General Terms and Conditions</h2>
                            <div className="uk-flex uk-flex-wrap uk-flex-middle uk-flex-center">

                            <a href="/allcor-tc-short.pdf" class="uk-button uk-button-default uk-button-large">Short</a>
                            <a href="/allcor-tc-full.pdf" class="uk-button uk-button-default uk-button-large">Full</a>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
    </Layout>
)

export default TermsPage


export const query = graphql`
  query termsQuery {
    pipe:file(relativePath: { eq: "new/PH06.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1200) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
  }
`;